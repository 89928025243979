export interface FeatureFlags {
  enable2025Pricing: boolean;
  // Temporary FF for onboarding as we don’t want to enable it on staging for now
  enable2025PricingOnboarding: boolean;
  enable2025PricingSubscriptionManagement: boolean;
  enableCashManagementCategories: boolean;
  enableCashFlowManagementV2: boolean;
  enableCashFlowMultiWallet: boolean;
  enableCompanyAddressSelfChange: boolean;
  enableEnhancedBirthCityInput: boolean;
  enableInternationalTransfer: boolean;
  enableMandateeOnboarding: boolean;
  enableMultipleTransactionDocs: boolean;
  enableNewLandbotFlow: boolean;
  enableSiretWall: boolean;
  enableSwiftTransfer: boolean;
  enableOnboarding600LayoutWidth: boolean;
  enableSasuActivities: boolean;
  enableSasuCapitalVariable: boolean;
  enableSasuAddress: boolean;
  enableInvoicingTradeNameOption: boolean;
  enabledNewPhysicalOrder: boolean;
  enableUrssafV2: boolean;
  enablePaymentCardPinRetrieval: boolean;
}

export const ALL_FEATURE_FLAGS = {
  // When working on your local machine
  development: {
    enable2025Pricing: true,
    enable2025PricingOnboarding: true,
    enable2025PricingSubscriptionManagement: true,
    enableCashFlowManagementV2: true,
    enableCashFlowMultiWallet: true,
    enableCashManagementCategories: true,
    enableCompanyAddressSelfChange: true,
    enabledNewPhysicalOrder: true,
    enableEnhancedBirthCityInput: true,
    enableInternationalTransfer: true,
    enableInvoicingTradeNameOption: true,
    enableMandateeOnboarding: true,
    enableMultipleTransactionDocs: false,
    enableNewLandbotFlow: true,
    enableOnboarding600LayoutWidth: true,
    enablePaymentCardPinRetrieval: true,
    enableSasuActivities: true,
    enableSasuAddress: true,
    enableSasuCapitalVariable: true,
    enableSiretWall: true,
    enableSwiftTransfer: true,
    enableUrssafV2: true,
  } satisfies FeatureFlags,
  // In production
  production: {
    enable2025Pricing: false,
    enable2025PricingOnboarding: false,
    enable2025PricingSubscriptionManagement: false,
    enableCashFlowManagementV2: true,
    enableCashFlowMultiWallet: true,
    enableCashManagementCategories: true,
    enableCompanyAddressSelfChange: false,
    enabledNewPhysicalOrder: true,
    enableEnhancedBirthCityInput: true,
    enableInternationalTransfer: false,
    enableInvoicingTradeNameOption: true,
    enableMandateeOnboarding: true,
    enableMultipleTransactionDocs: false,
    enableNewLandbotFlow: true,
    enableOnboarding600LayoutWidth: false,
    enablePaymentCardPinRetrieval: true,
    enableSasuActivities: true,
    enableSasuAddress: true,
    enableSasuCapitalVariable: true,
    enableSiretWall: true,
    enableSwiftTransfer: true,
    enableUrssafV2: true,
  } satisfies FeatureFlags,
  // In staging
  staging: {
    enable2025Pricing: true,
    enable2025PricingOnboarding: true,
    enable2025PricingSubscriptionManagement: true,
    enableCashFlowManagementV2: true,
    enableCashFlowMultiWallet: true,
    enableCashManagementCategories: true,
    enableCompanyAddressSelfChange: true,
    enabledNewPhysicalOrder: true,
    enableEnhancedBirthCityInput: true,
    enableInternationalTransfer: false,
    enableInvoicingTradeNameOption: true,
    enableMandateeOnboarding: true,
    enableMultipleTransactionDocs: false,
    enableNewLandbotFlow: true,
    enableOnboarding600LayoutWidth: true,
    enablePaymentCardPinRetrieval: true,
    enableSasuActivities: true,
    enableSasuAddress: true,
    enableSasuCapitalVariable: true,
    enableSiretWall: true,
    enableSwiftTransfer: true,
    enableUrssafV2: true,
  } satisfies FeatureFlags,
  // When running automated tests
  testing: {
    enable2025Pricing: false,
    enable2025PricingOnboarding: false,
    enable2025PricingSubscriptionManagement: false,
    enableCashFlowManagementV2: true,
    enableCashFlowMultiWallet: true,
    enableCashManagementCategories: true,
    enableCompanyAddressSelfChange: true,
    enabledNewPhysicalOrder: true,
    enableEnhancedBirthCityInput: true,
    enableInternationalTransfer: false,
    enableInvoicingTradeNameOption: true,
    enableMandateeOnboarding: true,
    enableMultipleTransactionDocs: false,
    enableNewLandbotFlow: true,
    enableOnboarding600LayoutWidth: true,
    enablePaymentCardPinRetrieval: true,
    enableSasuActivities: true,
    enableSasuAddress: false,
    enableSasuCapitalVariable: true,
    enableSiretWall: true,
    enableSwiftTransfer: true,
    enableUrssafV2: true,
  } satisfies FeatureFlags,
};

export type FeatureFlagsEnv = keyof typeof ALL_FEATURE_FLAGS;
export type FeatureFlagsKey = keyof FeatureFlags;

const getFeatureFlags = (): FeatureFlags => {
  switch (import.meta.env.VITE_FEATURES_ENV) {
    case 'production':
      return ALL_FEATURE_FLAGS.production;

    case 'staging':
      return ALL_FEATURE_FLAGS.staging;

    case 'testing':
      return ALL_FEATURE_FLAGS.testing;

    default:
      return ALL_FEATURE_FLAGS.development;
  }
};

const featureFlags = getFeatureFlags();

export default featureFlags;
